import React, { useState, useRef, useEffect } from 'react';
import "./SoftwareDevelopment.css"
// import CloudifyTech from "./CloudifyTech.png";
import ElegetLogo from "../../Images/ElegetLogo.png";
import HoverImg1 from "../../Images/HoverImg1.png";
import HoverImg2 from "../../Images/HoverImg2.png";
import Triangle from "../../Images/Triangle.png";



export const SoftwareDevelopment = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();

    const x = e.clientX - (rect.left + rect.width / 2);
    const y = e.clientY - (rect.top + rect.height / 2);

    const distance = Math.sqrt(x * x + y * y);
    const directionX = (x / distance) * 10;
    const directionY = (y / distance) * 10;

    setPosition({
      x: -directionX,
      y: -directionY,
    });
  };






  //validating animation 
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef(null);
  const hasActivated = useRef(false); // Track if animation has been activated

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasActivated.current) {
          setIsVisible(true);
          hasActivated.current = true;
        }
      },
      {
        threshold: 0.1,
      }
    );

    const currentDiv = divRef.current;

    if (currentDiv) {
      observer.observe(currentDiv);
    }

    return () => {
      if (currentDiv) {
        observer.unobserve(currentDiv);
      }
    };
  }, []);


  const divStyle1 = {
    animation: isVisible ? 'slideInFromTop 1s ease-out forwards' : 'none',
  };
  const divStyle2 = {
    animation: isVisible ? 'slideInFromRight 1s ease-out forwards' : 'none',
  };
  const divStyle3 = {
    animation: isVisible ? 'slideInFromLeft 1s ease-out forwards' : 'none',
  };






  return (
    <div className='s_d_whole_page_div'>
      <div className='S_D_main_div' style={divStyle3} ref={divRef}>
        <div className='s_d_heading'>Software Development Company</div>
        <p>If you're rebuilding your product, scaling your startup or building an internal tool, we've got your back. We specialize in go-to-market and growth strategy. technology advisory and innovation roadmaps.</p>
      </div>

      <div className="movable-div" style={{ transform: `translate(${position.x}px, ${position.y}px)`, }} onMouseMove={handleMouseMove}  >
        <div><img className='logo_img_cloudify' width="180" height="45" src={ElegetLogo} alt=""
          style={divStyle1} ref={divRef}
        /></div>

        <div><img className='circle_lines_img' src={HoverImg1} alt="" style={divStyle1} ref={divRef} /></div>
        <div className='third_fourth_img'>
          <div><img className='aabbcc' src={HoverImg2} alt=""
            style={divStyle2} ref={divRef} /></div>
        </div>
        <div className='s_d_triangle_img' ><img src={Triangle} alt="" /></div>
      </div>

    </div>
  )
}
